const ChevronRightIcon = ({className, stroke = 'currentColor', fill = 'none'}) => <svg
  className={className}
  stroke={stroke}
  fill={fill}
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
>
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
</svg>;

export default ChevronRightIcon;