const ListIcon = ({className, stroke = 'currentColor', fill = 'currentColor'}) => {
  return <svg
    className={className}
    stroke={stroke}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 6h16M4 10h16M4 14h16M4 18h16"
    />
  </svg>;
};

export default ListIcon;